import View from "./View.js";
import icons from 'url:../../img/icons.svg';
import fracty from 'fracty';


class shoppingCartView extends View {
    _parentElement = document.querySelector(".ing");
    _message = '';
    _errorMessage = 'no ingredients added';
    _window = document.querySelector(".add-ing-window-2");
    _overlay = document.querySelector(".overlay-2");
    _btnOpen = document.querySelector(".nav__btn--shopping");
    _btnClose = document.querySelector(".btn--close-modal-2");

    constructor() {
        super();
        this._addHandelerShowWindow();
        this._addHandelerHideWindow();
    };

    toggleWindow () {
        this._overlay.classList.toggle('hidden');
        this._window.classList.toggle('hidden');
    };

    _addHandelerShowWindow() {
        this._btnOpen.addEventListener('click', this.toggleWindow.bind(this));
    };

    _addHandelerHideWindow() {
        this._btnClose.addEventListener('click', this.toggleWindow.bind(this));
        this._overlay.addEventListener('click', this.toggleWindow.bind(this));
    };
    /**
     * 
     * @param {Function} handler this function  will be called when user submit the form 
     */
    addHandelerRenderCart (handler) {
        window.addEventListener('load', handler);
        console.log(this._data)
    };

    _generateMarkup () {
        return `
            
          <ul class="recipe__ingredient-list">
            ${this._data.map(el => el.ingredients.map(this._generateRecipeIng).join('')).join('')}
          </ul>
        `
    };
    
    // _sumIngredients (ing) {
    //   return `
    //     <li class="recipe__ingredient">
    //       <svg class="recipe__icon">
    //         <use href="${icons}#icon-check"></use>
    //       </svg>
    //       <div class="recipe__quantity">${fracty(this._sumIngredients(ing.description)) === 0 ? '': fracty(this._sumIngredients(ing.description)) }</div>
    //       <div class="recipe__description">
    //         <span class="recipe__unit">${ing.unit}</span>
    //         ${ing.description}
    //       </div>
    //     </li>
    // `
    //   // return this._data
    //   // .map(el => {
    //   //     el.ingredients
    //   //     .filter(i=> i.description === des)
    //   //     .reduce((acc, cur) => acc + cur, 0)
    //   //   })
      
    // }
    _generateRecipeIng (ing) {
        return `
          <li class="recipe__ingredient">
            <svg class="recipe__icon">
              <use href="${icons}#icon-check"></use>
            </svg>
            <div class="recipe__quantity">${fracty(ing.quantity) === 0 ? '': fracty(ing.quantity) }</div>
            <div class="recipe__description">
              <span class="recipe__unit">${ing.unit}</span>
              ${ing.description}
            </div>
          </li>
        `
      }
}

export default new shoppingCartView()