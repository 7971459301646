class SearchView {
    #parentEl = document.querySelector('#responsive--form');
    constructor(){
        this._responsiveNav();
        this._addResponsiveSearchBar();
    }
    getQuery() {
        const query = this.#parentEl.querySelector('.search__field').value;
        this.clearInput();
        return query;
    };

    clearInput() {
        this.#parentEl.querySelector('.search__field').value = '';
    };
    
    addSearchHandler(handler) {
        // this.#parentEl.addEventListener("submit", handler);
        this.#parentEl.addEventListener("submit", function(e) {
            e.preventDefault();
            handler();
        })
    }
    _addResponsiveSearchBar() {
        // Get the search bar and its parent container
        if(window.innerWidth > 980 )console.log(window.innerWidth);
        const btn = document.querySelector('.responsive');

        btn.addEventListener('click', (e) => {
            e.preventDefault();
            this.#parentEl.classList.remove('search');
            btn.style.display = 'none';
            this.#parentEl.style.display = 'block';
            document.querySelector('.search__btn').style.display = 'none';
        })
    }    
    _responsiveNav() {
        const menu = document.querySelector('.menu');
        menu.addEventListener('click', function(e){
            const x = document.getElementById("myTopnav");
            this.classList.toggle("change");
            if (x.className === "nav") {
              x.className += "responsive";
              e.target.style.display = 'none';
            } else {
              x.className = "nav";
              e.target.style.display = 'block';
            }
        })


    }
}

export default new SearchView();