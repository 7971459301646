import { mark } from 'regenerator-runtime';
import icons from 'url:../../img/icons.svg';

export default class View {
    _data;
  /**
   * the recieved view object with a new property "render" that is a function to call when rendering the view to the DOM
   * @param {Object | Object[]} data  - Data to be rendered (e.g. recipe). Can be a single object or an array of objects.
   * @param {boolean} [render = true] - if false, create markup string instead of rendering to the DOM .
   * @returns {undefined | string} A markup string is returned if render is false
   * @this {Object} view instance
   * @author Rofaida
   */
    render (data, render=true) {
      if(!data || (Array.isArray(data) && data.length === 0)) return this.renderError();
        this._data = data;
        const markup = this._generateMarkup();
        if(!render) return markup;
        
        this._clear();
        this._parentElement.insertAdjacentHTML('afterbegin', markup)
    }
/**
 * this function is updating the DOM 
 * @param {Object | Object[]} data data to be updated without rendering the view 
 */
    update (data) {
      this._data = data;
      const newMarkup = this._generateMarkup();
      const newDOM = document.createRange().createContextualFragment(newMarkup);
      const newElm = Array.from(newDOM.querySelectorAll('*'));
      const curElm = Array.from(this._parentElement.querySelectorAll('*'));
      
      newElm.forEach((elm, i) => {
        const currentE = curElm[i];
        
        // 1) Jonas way: 
        // if(!elm.isEqualNode(currentE) && elm.firstChild?.nodeValue.trim() !== '') {
        //   currentE.textContent = elm.textContent;
        // }

        // 2) my way:
        if(!elm.isEqualNode(currentE)){
          currentE.innerHTML = elm.innerHTML;
        }

        // if(!elm.isEqualNode(currentE)) {
        //   Array.from(elm.attributes).forEach(attr => currentE.setAttributes(attr.name, attr.value))
        // }

        
      })

    }
    _clear () {
        this._parentElement.innerHTML = '';

    };

    renderSpinner(){
        const spinner = `
          <div class="spinner">
            <svg>
              <use href="${icons}#icon-loader"></use>
            </svg>
          </div>
        `
        this._clear()
        this._parentElement.insertAdjacentHTML('afterbegin', spinner);
    };

    renderError(message = this._errorMessage){
      const markup = `
        <div class="error">
          <div>
            <svg>
              <use href="${icons}#icon-alert-triangle"></use>
            </svg>
          </div>
          <p>${message}</p>
        </div>
      `
      this._clear();
      this._parentElement.insertAdjacentHTML('afterbegin', markup);
    };

    renderMessage(message = this._message){
      
      const markup = `
        <div class="message">
          <div>
            <svg>
              <use href="${icons}#icon-smile"></use>
            </svg>
          </div>
          <p>${message}</p>
        </div>
      `
      this._clear();
      this._parentElement.insertAdjacentHTML('afterbegin', markup);
    };

}