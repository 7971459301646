import View from "./View.js";
import icons from 'url:../../img/icons.svg';
import previewView from "./previewView.js";


class AddRecipeView extends View {
    _parentElement = document.querySelector(".upload");
    _message = 'Recipe was successfully uploaded';
    _window = document.querySelector(".add-recipe-window");
    _overlay = document.querySelector(".overlay");
    _btnOpen = document.querySelector(".nav__btn--add-recipe");
    _btnClose = document.querySelector(".btn--close-modal");

    constructor() {
        super();
        this._addHandelerShowWindow();
        this._addNewIng();
        this._addInputValidation();
        this._addHandelerHideWindow();
    }

    toggleWindow () {
        this._overlay.classList.toggle('hidden');
        this._window.classList.toggle('hidden');
    };

    _addHandelerShowWindow() {
        this._btnOpen.addEventListener('click', this.toggleWindow.bind(this));
    };

    _addHandelerHideWindow() {
        this._btnClose.addEventListener('click', this.toggleWindow.bind(this));
        this._overlay.addEventListener('click', this.toggleWindow.bind(this));
    };
    /**
     * 
     * @param {Function} handler this function  will be called when user submit the form 
     */
    addHandelerUpload (handler) {
        this._parentElement.addEventListener('submit', function(e) {
            e.preventDefault();
            const dataArr = [...new FormData(this)];
            const data = Object.fromEntries(dataArr);
            document.querySelector('.btn--add-ingredient').classList.add('hidden');
            handler(data);
        })
    };
    _addNewIng () {
        let ing = 6;
        let newInput;
        let newLabel;
        document.querySelector('.btn--add-ingredient').addEventListener( "click", function(){
            ing++;
            newInput = document.createElement("input"); 
            newLabel = document.createElement("label");
            newInput.classList.add('ingredients');
            newInput.placeholder = "Format: 'Quantity,Unit,Description'";
            newInput.name = `ingredient-${ing}`;
            newLabel.innerHTML = `Ingredient ${ing}`;
            newLabel.htmlFor = `ingredient ${ing}`;
            newInput.id = `ingredient ${ing}`;
            document.querySelector('.upload_ingredients').append(newLabel, newInput);
        })
    }
    _addInputValidation(){
        const ingInputs = document.querySelectorAll('.ingredients');
        const format = /[0-9]*,[a-z]*,[a-z]*/gi;
        ingInputs.forEach(inp => {
            inp.oninput = function(e) {
                let result = e.target.value.match(format);
                if(!result || !result[0]){
                    inp.classList.add('invalid');
                }
                else inp.classList.remove('invalid');
            }; 
        })
        
    }
    
    
    
    _generateMarkup () {};
}

export default new AddRecipeView()