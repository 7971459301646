import View from "./View.js";
import previewView from "./previewView.js";


class BookmarksView extends View {
    _parentElement = document.querySelector(".bookmarks__list");
    _errorMessage = 'No bookmarks yet!';
    _message = '';

    _generateMarkup () {
        return`${this._data.map(bookmark => previewView.render(bookmark, false)).join('')}`
    }
    addHandelerRender (handler) {
        window.addEventListener('load', handler);
    }
}

export default new BookmarksView()