import * as model from './model.js';
import recipeView from './views/recipeView.js';

import'regenerator-runtime/runtime';
import searchView from './views/searchView.js';
import resaultsView from './views/resaultsView.js';
import paginationView from './views/paginationView.js';
import addRecipeView from './views/addRecipeView.js';
import bookmarksView from './views/bookmarksView.js';
import { async } from 'regenerator-runtime';
import 'core-js/stable';
import { MODAL_CLOSE_SEC } from './config.js';
import shoppingCartView from './views/shoppingCartView.js';


const recipeContainer = document.querySelector('.recipe');



// https://forkify-api.herokuapp.com/v2

///////////////////////////////////////
const controllRecipes = async function(){
  try {
    const id = window.location.hash.slice(1);
    if(!id) return;
    recipeView.renderSpinner();
    //0) update the selected result
    resaultsView.update(model.getSearchResultsPage())
    bookmarksView.update(model.state.bookmarks);
    shoppingCartView.update(model.state.shoppingCart);
    // 1)load recipe
    await model.loadRecipe(id);
    // 2) rendering recipe in UI
    recipeView.render(model.state.recipe);



  } catch (error) {
    // alert(error)
    console.error(error);
    recipeView.renderError();
  }
  
};

const controlSearchResults = async function() {
  try {
    resaultsView.renderSpinner();
    const query = searchView.getQuery();
    if(!query) return;

    // 1) show search results
    await model.loadSearchResults(query);
    resaultsView.render(model.getSearchResultsPage());
    //2) initiale pagination
    paginationView.render(model.state.search)
  } catch (err) {
    console.error(err);
    resaultsView.renderError(err);
  }
}

const controlPaginationBtn = function(goToPage) {
  
  resaultsView.render(model.getSearchResultsPage(goToPage));
  //2) new pagination
  paginationView.render(model.state.search)
}

// window.addEventListener("hashchange", controllRecipes)
// window.addEventListener("load", controllRecipes)

const controlServings = function(updateTo) {
  model.updateServings(updateTo);
  // recipeView.render(model.state.recipe);
  recipeView.update(model.state.recipe);

};

const controlAddBookmark = function(){
  if(!model.state.recipe.bookmarked){
    model.addBookmark(model.state.recipe);
  }  
  else {
    model.deleteBookmark(model.state.recipe.id);
  };

  recipeView.update(model.state.recipe);
  bookmarksView.render(model.state.bookmarks)

};

const controlBookmarks = function() {
  bookmarksView.render(model.state.bookmarks);
};

const controlAddShoppingCart = function(){
  if(!model.state.recipe.saved){
    model.addToCart(model.state.recipe);
  }  
  else {
    model.removeFromCart(model.state.recipe.id);
  };

  recipeView.update(model.state.recipe);
  shoppingCartView.render(model.state.shoppingCart);
  // console.log(model.state.shoppingCart)
  

};

const controlShoppingCart = function() {
  shoppingCartView.render(model.state.shoppingCart);
};

const controlAddRecipe = async function(newRecipe) {
  try {

    //show loading spinner
    addRecipeView.renderSpinner();

    await model.uploadRecipe(newRecipe);
    
    //render recipe
    recipeView.render(model.state.recipe);

    //Success message
    addRecipeView.renderMessage();

    //add bookmark
    bookmarksView.render(model.state.bookmarks);

    //change URL 
    window.history.pushState(null, '', `#${model.state.recipe.id}`)

    //close modal
    setTimeout(function(){
      addRecipeView.toggleWindow();
    }, MODAL_CLOSE_SEC * 1000)
  } catch (err) {
    console.error(err);
    addRecipeView.renderError(err.message);
  }
};

const init = function(){
  bookmarksView.addHandelerRender(controlBookmarks);
  shoppingCartView.addHandelerRenderCart(controlShoppingCart);
  recipeView.addRenderHandler(controllRecipes);
  recipeView.addHandlerUpdateServings(controlServings);
  recipeView.addHandlerBookmark(controlAddBookmark);
  recipeView.addHandlerShoppingCart(controlAddShoppingCart);
  searchView.addSearchHandler(controlSearchResults);
  paginationView.addHandlerClick(controlPaginationBtn);
  addRecipeView.addHandelerUpload(controlAddRecipe);
};
init();
