import { async } from "regenerator-runtime";
import { API_URL, RES_PER_PAGE, KEY } from "./config";
import { AJAX } from "./helpers";

export const state = {
    recipe: {},
    search: {
        query: '',
        results: [],
        page: 1,
        resultsPerPage: RES_PER_PAGE,
    },
    bookmarks: [],
    shoppingCart: [],
};

export const loadRecipe = async function (id) {
    try{
        // 1)get data from promise
        const data = await AJAX(`${API_URL}/${id}?key=${KEY}`);
        const {recipe} = data.data;

        state.recipe = {
            id: recipe.id,
            title: recipe.title,
            publisher: recipe.publisher,
            sourceUrl: recipe.source_url,
            image: recipe.image_url,
            servings: recipe.servings,
            cookingTime: recipe.cooking_time,
            ingredients: recipe.ingredients,
            ...(recipe.key && { key: recipe.key }),
        }
        if(state.bookmarks.some(bookmark =>bookmark.id === id))state.recipe.bookmarked = true;
        else state.recipe.bookmarked = false;
        
        if(state.shoppingCart.some(el =>el.id === id))state.recipe.saved = true;
        else state.recipe.saved = false;
    }catch(err){
        // alert("Error loading");
        console.error(err);
        throw err;
    }

};

export const loadSearchResults = async function(query) {
    try {
        state.search.query = query;
        const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`);

        state.search.results = data.data.recipes.map( rec => {

            return {
                id: rec.id,
                title: rec.title,
                publisher: rec.publisher,
                image: rec.image_url,
                ...(rec.key && { key: rec.key }),
            }
        })
        
        
        // console.log(state.search.results)

        state.search.page = 1;

    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const getSearchResultsPage = function(page = state.search.page) {
    state.search.page = page;
    const start = (page - 1) * state.search.resultsPerPage;
    const end = page * state.search.resultsPerPage;
    return state.search.results.slice(start, end);
}

export const updateServings = function(newServings){
    
    state.recipe.ingredients.forEach(ing => {
        ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
    });

    state.recipe.servings = newServings;
}

export const presistBookmark = function(){
    localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks));
};

export const presistShoppingCart = function(){
    localStorage.setItem('shopping-cart', JSON.stringify(state.shoppingCart));
};

export const addBookmark = function(recipe) {
    //add bookmark
    state.bookmarks.push(recipe);

    //mark current recipe as bookmark
    if(recipe.id === state.recipe.id)state.recipe.bookmarked = true;
    presistBookmark();
};


export const deleteBookmark = function(id) {
    const index = state.bookmarks.findIndex(el => el.id === id);
    state.bookmarks.splice(index, 1);
    if(id === state.recipe.id)state.recipe.bookmarked = false;
    presistBookmark();

}

// export const deleteFromCart = function(des) {
//     const  index = state.shoppingCart.findIndex(ing => ing.description === des);
//     state.shoppingCart.splice(index, 1);
//     if(des === state.recipe.ingredients)
// }

export const addToCart = function(recipe) {
    //add current recipe'ingredients to the cart
    state.shoppingCart.push(recipe);
    if(recipe.id === state.recipe.id)state.recipe.saved = true;
    presistShoppingCart();
        
    
};


export const removeFromCart = function(id) {
    
    const index = state.shoppingCart.findIndex(el => el.id === id);
    state.shoppingCart.splice(index, 1)
    if(id === state.recipe.id)state.recipe.saved = false; 
    presistShoppingCart();
    
};

const init = function() {
    const bookmarkStorage = localStorage.getItem('bookmarks');
    const cartStorage = localStorage.getItem('shopping-cart')
    if(bookmarkStorage) state.bookmarks = JSON.parse(bookmarkStorage);
    if(cartStorage) state.shoppingCart = JSON.parse(cartStorage);

}
init();
export const clearStorage = function() {
    localStorage.clear('shopping-cart');
}
// clearStorage();
const createRecipeObject = function(data) {
    const {recipe} = data.data;
    return {
        id: recipe.id,
        title: recipe.title,
        publisher: recipe.publisher,
        sourceUrl: recipe.source_url,
        image: recipe.image_url,
        servings: recipe.servings,
        cookingTime: recipe.cooking_time,
        ingredients: recipe.ingredients
    };
};

export  const uploadRecipe = async function(newRecipe) {
    // debugger;
    try{    
        const ingredients = Object.entries(newRecipe)
        .filter(entry => entry[0].startsWith('ingredient') && entry[1] !== '')
        .map(ing =>{
            const newArr = ing[1].split(',').map(el => el.trim());
            // const newArr = ing[1].replaceAll(' ', '').split(',');
            if(newArr.length !==3)throw new Error('Wrong ingredient format, try again!')
            const [quantity, unit, description] = newArr;
            return { quantity: +quantity ? quantity:null, unit, description };

        });
        
        const recipe = {
            title: newRecipe.title,
            publisher: newRecipe.publisher,
            source_url: newRecipe.sourceUrl,
            image_url: newRecipe.image,
            servings: +newRecipe.servings,
            cooking_time: +newRecipe.cookingTime,
            ingredients,      
        };

        const data = await AJAX(`${API_URL}?key=${KEY}`, recipe);
        state.recipe = createRecipeObject(data);
        addBookmark(state.recipe);
        // console.log(state.recipe)
    }catch(err){
        throw err;
    }
};

export const deleteRecipe = async function(id) {
    try{
        const data = await fetch(`${API_URL}/${id}?key=${KEY}`,{method: "DELETE"});
        // console.log(data);
    }catch(err){
        console.error(err);
    }
}
