import View from "./View.js";
import icons from 'url:../../img/icons.svg';


class PaginationView extends View {
    _parentElement = document.querySelector(".pagination");
    _generateMarkup() {
        const curPage = this._data.page;
        const numPages = Math.ceil(this._data.results.length / this._data.resultsPerPage);
        //page1, there are other  pages too
        if(curPage === 1 && numPages > 1) {
            return`
                <div class='pagination__num'>${numPages - curPage} pages left</div>
                <button data-goto=${curPage + 1} class="btn--inline pagination__btn--next">
                    <span>Page ${curPage + 1}</span>
                    <svg class="search__icon">
                        <use href="${icons}#icon-arrow-right"></use>
                    </svg>
                </button>
            `
        };

        //last page
        if(curPage === numPages && numPages > 1) {
            return`
                <div class='pagination__num'>${numPages - curPage} pages left</div>
                <button data-goto=${curPage - 1} class="btn--inline pagination__btn--prev">
                    <span>Page ${curPage - 1}</span>
                    <svg class="search__icon">
                    <use href="${icons}#icon-arrow-left"></use>
                    </svg>
                </button>
            `
        };

        //other pages
        if(curPage < numPages) {
            return`
                <div class='pagination__num'>${numPages - curPage} pages left</div>

                <button data-goto=${curPage - 1} class="btn--inline pagination__btn--prev">
                    <span>Page ${curPage - 1}</span>
                    <svg class="search__icon">
                    <use href="${icons}#icon-arrow-left"></use>
                    </svg>
                </button>
                <button data-goto=${curPage + 1} class="btn--inline pagination__btn--next">
                    <span>Page ${curPage + 1}</span>
                    <svg class="search__icon">
                    <use href="${icons}#icon-arrow-right"></use>
                    </svg>
                </button>
            `
            
        };

        //page1, there are no  pages
        return'';


    }

    addHandlerClick(handler) {
        this._parentElement.addEventListener( 'click', function(e){
            e.preventDefault();
            const btn = e.target.closest('.btn--inline');
            if(!btn) return;
            const goToPage = +btn.dataset.goto;
            handler(goToPage)
        })
    }
}

export default new PaginationView();